
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Terms } from '@/models/dto/Terms'
import CUElectronicSignature from '@/components/CUElectronicSignature.vue'

@Component({ components: { CUElectronicSignature } })
export default class CheckoutTermsModal extends Vue {
  @Prop({ required: true }) readonly terms!: Terms
  @Prop({ required: true }) readonly showModal!: boolean
  @Prop({ required: true }) readonly color!: string
  @Prop({ required: false, default: false }) readonly requireSignature!: boolean

  fullName = ''
  email = ''
  termsAccepted = false

  get termsAndConditions(): string {
    return this.terms?.body || ''
  }

  get formComplete(): boolean {
    return !!this.fullName && !!this.email && !!this.termsAccepted
  }

  acceptTerms(): void {
    if (this.requireSignature) {
      this.$emit('accept', this.fullName, this.email, this.termsAccepted)
      return
    }
    this.$emit('accept')
  }

  handleModalInput(val: boolean): void {
    if (!val) {
      this.$emit('close')
    }
  }
}
